import {
  faDiscord,
  faFacebookSquare,
  faInstagram,
  faTiktok,
  faXTwitter,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { assetPrefix } from "@sid/core/vars/assetUrl";
import dayjs from "dayjs";
import { useTranslation } from "next-i18next";
import Link from "next/link";
import LanguageChooser from "./LanguageChooser";

import { blogAlternate } from "@sid/core/vars/appAlternate";
import { showPreferences } from "vanilla-cookieconsent";

const NewFooter = () => {
  const { t } = useTranslation();
  const sitemaps = [
    {
      label: t("services"),
      menus: [
        {
          label: t("microsite_builder"),
          href: "/microsite",
        },
        {
          label: t("url_shortener"),
          href: "/shortener",
        },
        {
          label: t("subscription"),
          href: "/subscription",
        },
      ],
    },
    {
      label: t("help_support"),
      menus: [
        {
          label: t("help"),
          href: "/page/help",
        },
        {
          label: t("report"),
          href: "/page/report",
        },
        {
          label: t("status"),
          href: "https://s.id/status",
        },
      ],
    },
    {
      label: t("legal"),
      menus: [
        {
          label: t("terms_of_service"),
          href: "/page/terms-of-service",
        },
        {
          label: t("privacy_policy"),
          href: "/page/privacy-policy",
        },
      ],
    },
    {
      label: t("other_stuff"),
      menus: [
        {
          label: t("blog"),
          href: blogAlternate
            ? `https://${blogAlternate}/?utm_source=sid-footer`
            : "https://blog.s.id?utm_source=sid-footer",
        },
        // {
        //   label: "Press Kit",
        //   href: "/media",
        // },
        {
          label: t("about_us"),
          href: "/about",
        },
        {
          label: t("sponsorship"),
          href: "/page/sponsorship",
        },
        // {
        //   label: "Careers",
        //   href: "/careers",
        // },
      ],
    },
  ];

  return (
    <footer id="main-footer">
      <div className="bg-black bg-opacity-50 text-white px-6 py-8 md:px-12 rounded-t-3xl mt-20">
        <div className="flex flex-wrap flex-1 max-w-[800px]">
          {sitemaps.map((item, index) => (
            <div key={index} className="w-6/12 md:flex-1 mb-4 md:mb-0">
              <h1 className="font-montserrat font-bold md:text-lg mb-2">
                {item.label}
              </h1>
              <ul>
                {item.menus.map((item, index) => (
                  <li className="mb-3 text-sm md:text-base`" key={index}>
                    <Link href={item.href} className="hover:underline">
                      {item.label}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
        <div className="flex-1 flex justify-end">
          <LanguageChooser />
        </div>
        <div className="xl:-mb-3 flex items-center mb-20 flex-col md:flex-row">
          <a
            href="https://adg.id?utm_source=sid-home"
            rel="follow"
            target="_blank"
          >
            <img
              src={assetPrefix + "images/adg-red-ring.svg"}
              alt="ADG Logo"
              className="w-24 shrink-0"
            />
          </a>
          <div className="flex-1 ml-6 text-sm font-worksans mt-2.5">
            &copy; s.id - {dayjs().year()} &nbsp; {t("managed_by")} PT Aidi
            Digital Global
          </div>
          <div className="w-full md:w-auto md:justify-end mt-5 md:mt-3 flex flex-col items-end">
            <div className="flex items-center justify-center">
              <a
                href="https://instagram.com/home.s.id"
                rel="noopener noreferrer"
                target="_blank"
                className="mx-1"
                aria-label="Instagram"
              >
                <FontAwesomeIcon
                  icon={faInstagram}
                  className="w-5 text-white transition text-opacity-70 hover:text-opacity-100"
                />
              </a>
              <a
                href="https://twitter.com/home_s_id"
                rel="noopener noreferrer"
                target="_blank"
                className="mx-1"
                aria-label="Twitter"
              >
                <FontAwesomeIcon
                  icon={faXTwitter}
                  className="w-5 text-white transition text-opacity-70 hover:text-opacity-100"
                />
              </a>
              <a
                href="https://facebook.com/socmed.s.id"
                rel="noopener noreferrer"
                target="_blank"
                className="mx-1"
                aria-label="Facebook"
              >
                <FontAwesomeIcon
                  icon={faFacebookSquare}
                  className="w-5 text-white transition text-opacity-70 hover:text-opacity-100"
                />
              </a>
              <a
                href="https://tiktok.com/@home.s.id"
                rel="noopener noreferrer"
                target="_blank"
                className="mx-1"
                aria-label="Tiktok"
              >
                <FontAwesomeIcon
                  icon={faTiktok}
                  className="w-5 text-white transition text-opacity-70 hover:text-opacity-100"
                />
              </a>

              <a
                href="https://s.id/discord"
                rel="noopener noreferrer"
                target="_blank"
                className="mx-1"
                aria-label="Tiktok"
              >
                <FontAwesomeIcon
                  icon={faDiscord}
                  className="w-5 text-white transition text-opacity-70 hover:text-opacity-100"
                />
              </a>
            </div>
            <a
              href="#"
              className="underline text-xs mt-1"
              onClick={(e) => {
                e.preventDefault();
                showPreferences();
              }}
            >
              Manage Cookies
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default NewFooter;
