import { ChevronRightIcon } from "@heroicons/react/24/solid";
import cookieDomain from "@sid/core/vars/cookieDomain";
import clsx from "clsx";
import JSCookie from "js-cookie";
import { Trans } from "next-i18next";
import { useRouter } from "next/router";
import { useMemo } from "react";
import ModalComponent, { ModalHeader, useModal } from "./Modal";

const languages = {
  af: {
    label: "Afrikaans",
    flag: "🇿🇦",
  },
  ar: {
    label: "Arabic",
    flag: "🇸🇦",
  },
  be: {
    label: "Belarusian",
    flag: "🇧🇾",
  },
  bd: {
    label: "Bangladesh",
    flag: "🇧🇩",
  },
  mm: {
    label: "Burmese",
    flag: "🇲🇲",
  },
  cn: {
    label: "Chinese Simplified",
    flag: "🇨🇳",
  },
  de: {
    label: "German",
    flag: "🇩🇪",
  },
  el: {
    label: "Greek",
    flag: "🇬🇷",
  },
  en: {
    label: "English",
    flag: "🇺🇸",
  },
  fr: {
    label: "France",
    flag: "🇫🇷",
  },
  hi: {
    label: "Hindi",
    flag: "🇮🇳",
  },
  id: {
    label: "Indonesia",
    flag: "🇮🇩",
  },
  it: {
    label: "Italy",
    flag: "🇮🇹",
  },
  jp: {
    label: "Japan",
    flag: "🇯🇵",
  },
  kh: {
    label: "Cambodia",
    flag: "🇰🇭",
  },
  ko: {
    label: "Korean",
    flag: "🇰🇷",
  },
  my: {
    label: "Malaysia",
    flag: "🇲🇾",
  },
  no: {
    label: "Norwegian",
    flag: "🇳🇴",
  },
  nl: {
    label: "Dutch",
    flag: "🇳🇱",
  },
  pa: {
    label: "Punjabi",
    flag: "🇵🇰",
  },
  ph: {
    label: "Philippines",
    flag: "🇵🇭",
  },
  pt: {
    label: "Portuguese",
    flag: "🇵🇹",
  },
  ru: {
    label: "Russian",
    flag: "🇷🇺",
  },
  sp: {
    label: "Spain",
    flag: "🇪🇸",
  },
  th: {
    label: "Thai",
    flag: "🇹🇭",
  },
  sv: {
    label: "Swedish",
    flag: "🇸🇪",
  },
  tr: {
    label: "Turkey",
    flag: "🇹🇷",
  },
  tw: {
    label: "Chinese Traditional (Taiwan)",
    flag: "🇭🇰",
  },
  uk: {
    label: "Ukrainian",
    flag: "🇺🇦",
  },
  vi: {
    label: "Vietnam",
    flag: "🇻🇳",
  },
};

const LanguageChooser = ({ light = false }) => {
  const { locale, asPath, push } = useRouter();
  const langModal = useModal();

  const currentLanguage = useMemo(() => {
    const current = languages[locale as string];
    if (current) {
      return current;
    }
    return { label: "-", flag: "-" };
  }, [locale]);

  const changeLocaleCookie = (locale) => {
    JSCookie.remove("NEXT_LOCALE");
    JSCookie.remove("NEXT_LOCALE", { domain: cookieDomain });
    JSCookie.set("NEXT_LOCALE", locale, { expires: 365, domain: cookieDomain });
    push(asPath, asPath, { locale });
  };

  return (
    <div className="text-gray-800 relative">
      <button
        className={clsx(
          "px-3 py-1 rounded-md flex items-center border max-w-[150px]",
          {
            "bg-gray-900 text-gray-300 border-gray-800": !light,
            "bg-white text-gray-700 border-gray-200 dark:bg-gray-950 dark:text-gray-300 dark:border-gray-800":
              light,
          }
        )}
        onClick={langModal.open}
      >
        <p className="truncate">
          {currentLanguage?.flag} {currentLanguage?.label}
        </p>
        <ChevronRightIcon
          className={clsx("w-5 ml-2 -mr-1", {
            "text-gray-500 dark:text-gray-200": light,
          })}
        />
      </button>
      <ModalComponent {...langModal} className="!max-w-2xl">
        <ModalHeader {...langModal}>
          <Trans i18nKey="choose_language">Choose Language</Trans>
        </ModalHeader>

        <div className="flex flex-wrap -m-1.5">
          {Object.entries(languages).map(([id, item]) => (
            <div key={id} className="w-full md:w-6/12 p-1.5">
              <button
                // locale={id}
                className={clsx(
                  "block p-3 rounded-md border transition-all w-full text-left",
                  id === locale
                    ? "bg-blue-100 dark:bg-blue-600 border-blue-500 text-blue-500 dark:border-blue-800 dark:text-white ring-4"
                    : "dark:border-slate-800 dark:bg-slate-950"
                )}
                // href={asPath}
                onClick={() => changeLocaleCookie(id)}
              >
                <>
                  <span>{item.flag}</span>{" "}
                  <span className="ml-1">{item.label}</span>
                </>
              </button>
            </div>
          ))}
        </div>
      </ModalComponent>
    </div>
  );
};

export default LanguageChooser;
